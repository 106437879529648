/**
 * В js `(NaN === NaN) === false`,
 * но `Object.is(NaN, NaN) === true`,
 * но `Object.is(-0, 0) === false`,
 * но `(-0 === 0) === true
 *
 * И прочие интересности
 */
export const sameValue = (a: unknown, b: unknown): boolean => {
  return Object.is(a, b) || a === b;
};
