import { isObject } from '../utils/isObject';

import { useEffectSDK } from './useEffectSDK';

const hasType = (
  event: MessageEvent<unknown>
): event is MessageEvent<{
  type: string;
}> => {
  return !!(event.data && typeof event.data === 'object' && 'type' in event.data && typeof event.data.type === 'string');
};

const hasUrlInPayload = (
  event: MessageEvent<unknown>
): event is MessageEvent<{
  payload: {
    url: string;
  };
}> => {
  return !(
    isObject(event.data) &&
    'payload' in event.data &&
    isObject(event.data.payload) &&
    'url' in event.data.payload &&
    typeof event.data.payload.url === 'string' &&
    event.data.payload.url
  );
};

const isRedirectInCurrentTab = (
  event: MessageEvent<unknown>
): event is MessageEvent<{
  type: 'PW_WIDGET_REDIRECT';
  payload: {
    url: string;
  };
}> => {
  return !!(hasType(event) && event.data.type === 'PW_WIDGET_REDIRECT' && hasUrlInPayload(event));
};

const isRedirectInNewTab = (
  event: MessageEvent<unknown>
): event is MessageEvent<{
  type: 'PW_WIDGET_REDIRECT_IN_NEW_TAB';
  payload: {
    url: string;
  };
}> => {
  return !!(hasType(event) && event.data.type === 'PW_WIDGET_REDIRECT_IN_NEW_TAB' && hasUrlInPayload(event));
};

export const useRedirectSDK = (iframe: HTMLIFrameElement | undefined) => {
  useEffectSDK(() => {
    if (!iframe) {
      return;
    }

    const onMessage = (event: MessageEvent<unknown>) => {
      if (isRedirectInCurrentTab(event)) {
        window.open(event.data.payload.url, '_self');
        return;
      }

      if (isRedirectInNewTab(event)) {
        const a = document.createElement('a');
        a.href = event.data.payload.url;
        a.target = '_blank';
        a.click();
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [iframe]);
};
