import { useStateSDK } from './useStateSDK';

const getCache = <T>(initial: T) => {
  let cache: T = initial;

  return {
    get: () => cache,
    set: (value: T) => {
      cache = value;
    },
  };
};

export const useMemoSDK = <T>(callback: () => T, deps: readonly unknown[]): T => {
  const [cache] = useStateSDK(() =>
    getCache({
      value: callback(),
      prevDeps: deps,
    })
  );

  const { value, prevDeps } = cache.get();

  if (prevDeps.some((dep, index) => deps[index] !== dep)) {
    const nextValue = callback();
    cache.set({ value: nextValue, prevDeps: deps });

    return nextValue;
  }

  return value;
};
