import { forseRender, onlyForHooksAddOnRender, onlyForHooksAddOnUnmount } from '../renderer';

type SetState<T> = (update: T | ((prev: T) => T)) => void;
type UseStateResult<T> = [T, SetState<T>];

const getUseState = () => {
  let index = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let hooks: UseStateResult<any>[] = [];

  onlyForHooksAddOnRender(() => {
    index = 0;
  });

  onlyForHooksAddOnUnmount(() => {
    index = 0;
    hooks = [];
  });

  const useStateSDK = <T>(initialValue: T | (() => T)): UseStateResult<T> => {
    const currentStateTuple = hooks[index];
    index += 1;

    if (currentStateTuple) {
      return currentStateTuple as never;
    }

    const useStateTuple = Array(2).fill(null) as UseStateResult<T>;
    const value = typeof initialValue === 'function' ? (initialValue as () => T)() : initialValue;

    const setState: SetState<T> = (update) => {
      if (!hooks.length) {
        console.error('useStateSDK called on an unmounted app. This is a memory leak and a bug.');
        return;
      }

      if (typeof update === 'function') {
        const prevValue = useStateTuple[0];
        const nextValue = (update as (prev: T) => T)(prevValue);

        if (prevValue === nextValue) {
          return;
        }

        useStateTuple[0] = nextValue;

        forseRender();

        return;
      }

      if (update === useStateTuple[0]) {
        return;
      }

      useStateTuple[0] = update;

      forseRender();
    };

    useStateTuple[0] = value;
    useStateTuple[1] = setState;
    hooks.push(useStateTuple);
    return useStateTuple;
  };

  return useStateSDK;
};

/**
 * Same as react's useState untill version 18 where state updates batching where introduced.
 * So this hook does not support batching.
 */
export const useStateSDK = getUseState();
