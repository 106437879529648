export type Subscription<TValue> = {
  subscribe: (listener: (value: TValue) => void) => VoidFunction;
  getValue: () => TValue;
  next: (value: TValue) => void;
  unsubscribeAll: VoidFunction;
};

export function makeSubscription<TValue>(
  initialValue?: TValue
  // eslint-disable-next-line @typescript-eslint/ban-types -- here i need "any non nullish value" type
): TValue extends {} ? Subscription<TValue> : TValue extends undefined ? Subscription<TValue | undefined> : Subscription<TValue> {
  let currentValue: TValue = initialValue as TValue;
  let listeners: ((value: TValue) => void)[] = [];

  function subscribe(listener: (value: TValue) => void): () => void {
    listeners.push(listener);

    return () => {
      listeners = listeners.filter((callback) => callback !== listener);
    };
  }

  function getValue(): TValue {
    return currentValue;
  }

  function next(value: TValue): void {
    currentValue = value;

    for (const listener of listeners) {
      listener(currentValue);
    }
  }

  function unsubscribeAll(): void {
    listeners = [];
  }

  return {
    subscribe,
    getValue,
    next,
    unsubscribeAll,
  } as never;
}
