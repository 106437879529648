import type { Options } from '../types';
import { isMessageFromWidget } from '../utils/isMessageFromWidget';
import { redirect } from '../utils/redirect';

import { useEffectSDK } from './useEffectSDK';

export const useMessageFromWidgetSDK = (
  paymentToken: string | null,
  iframe: HTMLIFrameElement | undefined,
  onWidgetEvent: Options['onWidgetEvent']
) => {
  useEffectSDK(() => {
    const postTokenMessage = () => {
      if (paymentToken && iframe?.contentWindow?.postMessage) {
        iframe.contentWindow?.postMessage(
          {
            type: 'PW_WIDGET_UPDATE_TOKEN',
            payload: {
              token: paymentToken,
            },
          },
          '*'
        );
      }
    };

    postTokenMessage();

    const onMessage = (event: MessageEvent<unknown>) => {
      if (!isMessageFromWidget(event)) {
        return;
      }

      const message = event.data;

      switch (message.type) {
        case 'PW_WIDGET_REDIRECT':
          redirect(message.payload.url);
          return;
        case 'PW_WIDGET_REDIRECT_IN_NEW_TAB':
          redirect(message.payload.url, true);
          return;
        case 'TOKEN_REQUEST':
          postTokenMessage();
          return;
        // Сообщения, которые обрабатывают бренды
        case 'PW_WIDGET_CLOSE':
        case 'PW_WIDGET_OPEN_RULES_PAGE':
        case 'PW_WIDGET_OPEN_PROFILE_EDIT_PAGE':
        case 'PW_WIDGET_REDIRECT_WITH_POPUP_VALUE':
        case 'PW_WIDGET_IFRAME':
        case 'PW_WIDGET_IFRAME_LOADER_READY':
        case 'PW_WIDGET_OPEN_BONUS_PROGRESS':
          onWidgetEvent?.(message);
          break;
        default:
          // Убеждаемся на уровне типов, что ВСЕ ивенты обработали
          message satisfies never;
          break;
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onWidgetEvent, paymentToken, iframe]);
};
