import type { Container } from '../types';

export const getContainer = (container: Container): HTMLElement | null => {
  if (container instanceof HTMLElement) {
    return container;
  }

  if (typeof container === 'string') {
    const element = document.querySelector(container);
    return element instanceof HTMLElement ? element : null;
  }

  throw new Error(`Invalid container value. Should be an instance of HTMLElement or a css selector. Received: "${container}"`);
};
