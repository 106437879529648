import { useEffectSDK } from './hooks/useEffectSDK';
import { useMemoSDK } from './hooks/useMemoSDK';
import { useMessageFromWidgetSDK } from './hooks/useMessageFromWidgetSDK';
import { useMonitoringSDK } from './hooks/useMonitoringSDK';
import { useRedirectSDK } from './hooks/useRedirectSDK';
import { useRefSDK } from './hooks/useRefSDK';
import { useRStatParamsSDK } from './hooks/useRStatParamsSDK';
import { getContainer } from './utils/getContainer';
import { sendWidgetEventState } from './utils/sendWidgetEventState';
import { iframe } from './iframe';
import type { Options } from './types';

/**
 * Это главный файл в котором всё происходит
 * называется useAppSDK просто чтобы линтер
 * был спокоен.
 */
export const useAppSDK = (options: Options) => {
  // Чтобы избежать триггера эффектов когда прокидывают анонимную функцию
  const onWidgetEventRef = useRefSDK(options.onWidgetEvent);
  onWidgetEventRef.current = options.onWidgetEvent;
  const rstatPushHandlerRef = useRefSDK(options.rstatPushHandler);
  rstatPushHandlerRef.current = options.rstatPushHandler;

  const rstatPushHandler = useMemoSDK(() => {
    const fn: (typeof options)['rstatPushHandler'] = (...args) => rstatPushHandlerRef.current?.(...args);
    return fn;
  }, [rstatPushHandlerRef]);

  const rstatCommonData = useRStatParamsSDK(options.userCurrency);

  const onWidgetEvent = useMemoSDK(() => {
    const handleWidgetEvent: NonNullable<Options['onWidgetEvent']> = (event) => {
      onWidgetEventRef.current?.(event);
    };

    return handleWidgetEvent;
  }, [onWidgetEventRef]);

  const derivedOptions = { ...options, onWidgetEvent, rstatPushHandler, container: getContainer(options.container) };

  const onIframeLoadedEvent = useMemoSDK(() => {
    return (iframeElement: HTMLIFrameElement) => {
      rstatPushHandler('iframe_loaded', rstatCommonData);
      onWidgetEvent({ type: 'IFRAME_LOADED', payload: { iframe: iframeElement } });
    };
  }, [onWidgetEvent, rstatCommonData, rstatPushHandler]);

  const onSetIfameRef = useMemoSDK(() => {
    let prev: HTMLIFrameElement | null = null;

    return (next: HTMLIFrameElement | null) => {
      if (prev !== next) {
        prev = next;
        onWidgetEvent({ type: 'SET_IFRAME_REF', payload: { iframe: next } });
      }
    };
  }, [onWidgetEvent]);

  const iframeElement = iframe({ ...derivedOptions, onLoad: onIframeLoadedEvent, onSetRef: onSetIfameRef });

  const iframeLoaded = String(iframeElement?.dataset.loaded) === 'true';
  const { paymentToken, isVisible } = derivedOptions;

  useEffectSDK(() => {
    if (iframeElement && iframeLoaded) {
      onWidgetEvent({ type: 'IFRAME_LOADED', payload: { iframe: iframeElement } });
    }
  }, [iframeElement, iframeLoaded, onWidgetEvent]);

  useRedirectSDK(iframeElement);
  useMonitoringSDK(derivedOptions);
  useMessageFromWidgetSDK(paymentToken, iframeElement, onWidgetEvent);

  const isOpen = isVisible && Boolean(paymentToken);

  useEffectSDK(() => {
    if (isOpen && paymentToken) {
      return sendWidgetEventState.sendWidgetEvent({
        type: 'PW_WIDGET_OPEN',
        payload: { token: paymentToken },
      });
    }

    if (!isOpen) {
      sendWidgetEventState.sendWidgetEvent({
        type: 'PW_WIDGET_CLOSE',
        // TODO: УДАЛИТЬ ЭТО ПОСЛЕ ПЕРЕЕЗДА НА SDK
        payload: 'SDK' as unknown as null,
      });
    }
  }, [isOpen, iframeElement, paymentToken]);

  useEffectSDK(() => {
    if (paymentToken && iframeElement && iframeLoaded) {
      onWidgetEvent?.({ type: 'WIDGET_READY', payload: { iframe: iframeElement } });
    }
  }, [paymentToken, iframeElement, onWidgetEvent, iframeLoaded]);
};
