import type { MessageFromWidget } from '../../utils/parent/types';

import { isObject } from './isObject';

const hasPayload = (data: Record<string, unknown>): data is { payload: Record<PropertyKey, unknown> } => {
  return isObject(data.payload);
};

const hasUrlInPayload = (data: Record<string, unknown>): data is { payload: { url: string } } => {
  if (hasPayload(data) && 'url' in data.payload && typeof data.payload.url === 'string') {
    try {
      // eslint-disable-next-line no-new
      new URL(data.payload.url);
      return true;
    } catch {
      return false;
    }
  }

  return false;
};

export const typeGuardsMap: {
  [eventType in MessageFromWidget['type']]: (event: { type: eventType }) => event is Extract<MessageFromWidget, { type: eventType }>;
} = {
  PW_WIDGET_CLOSE: (_event): _event is Extract<MessageFromWidget, { type: 'PW_WIDGET_CLOSE' }> => {
    return true;
  },
  PW_WIDGET_OPEN_PROFILE_EDIT_PAGE: (_event): _event is Extract<MessageFromWidget, { type: 'PW_WIDGET_OPEN_PROFILE_EDIT_PAGE' }> => {
    return true;
  },
  PW_WIDGET_OPEN_RULES_PAGE: (_event): _event is Extract<MessageFromWidget, { type: 'PW_WIDGET_OPEN_RULES_PAGE' }> => {
    return true;
  },
  PW_WIDGET_REDIRECT: (event): event is Extract<MessageFromWidget, { type: 'PW_WIDGET_REDIRECT' }> => {
    return hasUrlInPayload(event);
  },
  PW_WIDGET_REDIRECT_IN_NEW_TAB: (event): event is Extract<MessageFromWidget, { type: 'PW_WIDGET_REDIRECT_IN_NEW_TAB' }> => {
    return hasUrlInPayload(event);
  },
  PW_WIDGET_REDIRECT_WITH_POPUP_VALUE: (event): event is Extract<MessageFromWidget, { type: 'PW_WIDGET_REDIRECT_WITH_POPUP_VALUE' }> => {
    return hasUrlInPayload(event);
  },
  PW_WIDGET_IFRAME: (event): event is Extract<MessageFromWidget, { type: 'PW_WIDGET_IFRAME' }> => {
    return hasUrlInPayload(event);
  },
  TOKEN_REQUEST: (_event): _event is Extract<MessageFromWidget, { type: 'TOKEN_REQUEST' }> => {
    return true;
  },
  PW_WIDGET_IFRAME_LOADER_READY: (_event): _event is Extract<MessageFromWidget, { type: 'PW_WIDGET_IFRAME_LOADER_READY' }> => {
    return true;
  },
  PW_WIDGET_OPEN_BONUS_PROGRESS: (_event): _event is Extract<MessageFromWidget, { type: 'PW_WIDGET_OPEN_BONUS_PROGRESS' }> => {
    return true;
  },
};

export const isMessageFromWidget = (event: MessageEvent<unknown>): event is MessageEvent<MessageFromWidget> => {
  if (isObject(event.data) && 'type' in event.data && typeof event.data.type === 'string') {
    return Boolean(typeGuardsMap[event.data.type as MessageFromWidget['type']]?.(event.data as never));
  }

  return false;
};
