import { MONITORING_URL } from '../constants';
import type { Options } from '../types';

import { useEffectSDK } from './useEffectSDK';
import { useRStatParamsSDK } from './useRStatParamsSDK';

type UseMonitoringParams = Pick<Options, 'makeGetRequest' | 'rstatPushHandler' | 'userCurrency'>;

const pingMonitoring = async (
  fetcher: Options['makeGetRequest'] = fetch
): Promise<[{ status: number; statusText: string }, null] | [null, Error]> => {
  try {
    const response = await fetcher(MONITORING_URL);

    if (!response.status || response.status >= 400) {
      return [null, new Error(response.statusText || `Monitoring failed with status ${String(response.status)}`)];
    }

    return [{ status: response.status, statusText: response.statusText || '' }, null];
  } catch (error) {
    if (error instanceof Error) {
      return [null, error];
    }

    return [null, new Error('Failed to fetch monitoring URL with unknown error')];
  }
};

export const useMonitoringSDK = ({ makeGetRequest, rstatPushHandler, userCurrency }: UseMonitoringParams) => {
  const rstatCommonData = useRStatParamsSDK(userCurrency);

  useEffectSDK(() => {
    let awaiting = true;

    const ping = async () => {
      const [response, error] = await pingMonitoring(makeGetRequest);

      if (!awaiting) {
        return;
      }

      if (error) {
        rstatPushHandler?.('fetch_widget_monitoring_failure', {
          errorMessage: error.message,
          error,
          ...rstatCommonData,
        });

        return;
      }

      rstatPushHandler?.('fetch_widget_monitoring_success', {
        status: response.status,
        statusText: response.statusText,
        ...rstatCommonData,
      });
    };

    ping();

    return () => {
      awaiting = false;
    };
  }, [rstatCommonData, makeGetRequest, rstatPushHandler]);
};
