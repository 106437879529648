import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getPaymentWidgetDevCookie = () => {
  return cookies.get('PW_DEV');
};

const getOrigin = (): string => {
  if (window.location.hostname.includes('dev')) {
    const widgetDev = getPaymentWidgetDevCookie();

    return widgetDev?.startsWith('dev') ? `https://${widgetDev}.paybetget.cash` : 'https://dev.paybetget.cash';
  }

  if (window.location.href.startsWith('http://localhost:')) {
    return 'http://localhost:5173';
  }

  return 'https://paybetget.cash';
};

export function getWidgetUrl({ brand, siteCode }: { brand: string; siteCode: string }): string {
  const url = new URL(getOrigin());
  url.searchParams.set('brand', brand);
  url.searchParams.set('siteCode', siteCode);

  return url.href;
}
