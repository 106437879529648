type RenderCallbackState = {
  callback: VoidFunction;
  state: 'waiting' | 'running' | 'completed';
};

type EffectFunction = () => void | undefined | VoidFunction;

type Effect = [effect: EffectFunction, deps: unknown[]];
type PrevEffect = [cleanup: VoidFunction, deps: unknown[] | null];

type State =
  | {
      stage: 'idle';
      renderQueue: [];
      renderQueueIndex: 0;
      effectsQueue: [];
      prevEffects: PrevEffect[];
      timeout: ReturnType<typeof setTimeout> | null;
    }
  | {
      stage: 'render';
      renderQueue: [RenderCallbackState, ...RenderCallbackState[]];
      renderQueueIndex: number;
      effectsQueue: Effect[];
      prevEffects: PrevEffect[];
      timeout: ReturnType<typeof setTimeout> | null;
    }
  | {
      stage: 'pre_effects';
      renderQueue: RenderCallbackState[];
      renderQueueIndex: number;
      effectsQueue: Effect[];
      prevEffects: PrevEffect[];
      timeout: ReturnType<typeof setTimeout> | null;
    }
  | {
      stage: 'running_effects';
      renderQueue: RenderCallbackState[];
      renderQueueIndex: number;
      effectsQueue: Effect[];
      prevEffects: PrevEffect[];
      timeout: ReturnType<typeof setTimeout>;
    };

type RenderEvent =
  | {
      type: 'RENDER';
      renderCallback: VoidFunction;
    }
  | {
      type: 'EFFECT';
      effect: Effect;
    }
  | {
      type: 'RENDER_DONE';
    }
  | {
      type: 'EFFECTS_DONE';
    }
  | {
      type: 'SET_EFFECTS_TIMEOUT';
      timeout: ReturnType<typeof setTimeout> | null;
    }
  | {
      type: 'RUNNING_EFFECTS';
      timeout: ReturnType<typeof setTimeout>;
    }
  | {
      type: 'RUN_RENDER_CALLBACK';
    }
  | {
      type: 'UNMOUNT';
    };

type StageMachine = {
  [stage in State['stage']]: {
    [event in RenderEvent['type']]?: (state: Extract<State, { stage: stage }>, event: Extract<RenderEvent, { type: event }>) => State;
  };
};

const getInitialState = (state?: Pick<State, 'prevEffects'>): State => ({
  stage: 'idle',
  renderQueue: [],
  renderQueueIndex: 0,
  effectsQueue: [],
  prevEffects: state?.prevEffects || [],
  timeout: null,
});

const machine: StageMachine = {
  idle: {
    RENDER: (state, event) => {
      return {
        ...state,
        stage: 'render',
        renderQueue: [{ callback: event.renderCallback, state: 'waiting' }],
        renderQueueIndex: 0,
      };
    },
  },
  render: {
    RENDER: (state, event) => {
      state.renderQueue.push({ callback: event.renderCallback, state: 'waiting' });

      return state;
    },
    RUN_RENDER_CALLBACK: (state) => {
      const renderCallback = state.renderQueue[state.renderQueueIndex];

      if (!renderCallback) {
        throw new Error('RUN_RENDER_CALLBACK called without a waiting render callback');
      }

      if (renderCallback.state !== 'waiting') {
        throw new Error('RUN_RENDER_CALLBACK called with a non-waiting render callback');
      }

      state.effectsQueue = [];
      renderCallback.state = 'running';
      return state;
    },
    EFFECT: (state, event) => {
      state.effectsQueue.push(event.effect);
      return state;
    },
    RENDER_DONE: (state) => {
      const currentCallback = state.renderQueue[state.renderQueueIndex];

      if (!currentCallback || currentCallback.state !== 'running') {
        throw new Error('RENDER_DONE called without a running render callback');
      }

      currentCallback.state = 'completed';
      state.renderQueueIndex += 1;

      if (!state.renderQueue[state.renderQueueIndex]) {
        return {
          ...state,
          stage: 'pre_effects',
          renderQueue: [],
          renderQueueIndex: 0,
          timeout: null,
        };
      }

      return state;
    },
  },
  pre_effects: {
    RENDER: (state, event) => {
      state.renderQueue.push({ callback: event.renderCallback, state: 'waiting' });
      state.stage = 'render' as never;

      if (state.timeout !== null) {
        clearTimeout(state.timeout);
        state.timeout = null;
      }

      return state;
    },
    SET_EFFECTS_TIMEOUT: (state, event) => {
      state.timeout = event.timeout;
      return state;
    },
    RUNNING_EFFECTS: (state, event) => {
      state.timeout = event.timeout;
      state.stage = 'running_effects' as never;
      return state;
    },
  },
  running_effects: {
    RENDER: (state, event) => {
      state.renderQueue.push({ callback: event.renderCallback, state: 'waiting' });
      return state;
    },
    EFFECTS_DONE: (state) => {
      clearTimeout(state.timeout);
      state.timeout = null as never;

      if (state.renderQueue.length > state.renderQueueIndex) {
        return {
          ...state,
          renderQueue: state.renderQueue as [RenderCallbackState, ...RenderCallbackState[]],
          effectQueue: [],
          currentRenderCallback: null,
          stage: 'render',
        };
      }

      return getInitialState(state);
    },
  },
};

const transition = (state: State, event: RenderEvent): State => {
  if (event.type === 'UNMOUNT') {
    if ('timeout' in state && state.timeout !== null) {
      clearTimeout(state.timeout);
    }

    state.timeout = setTimeout(() => {
      for (const [cleanup] of state.prevEffects) {
        cleanup();
      }
    }, 0);

    return getInitialState();
  }

  return machine[state.stage][event.type]?.(state as never, event as never) || state;
};

const depsAreEqual = (prev: unknown[] | null, next: unknown[]): boolean => {
  if (!prev) {
    return false;
  }

  if (prev.length !== next.length) {
    return false;
  }

  if (prev === next) {
    return true;
  }

  for (let i = 0; i < prev.length; i += 1) {
    const prevI = prev[i];
    const nextI = next[i];

    if (!(Object.is(prevI, nextI) || prevI === nextI)) {
      return false;
    }
  }

  return true;
};

const getScheduler = () => {
  // eslint-disable-next-line no-underscore-dangle
  let _state: State = getInitialState();

  const getState = () => _state;

  const send = (event: RenderEvent) => {
    _state = transition(getState(), event);
    // eslint-disable-next-line no-use-before-define
    onStateChange();
  };

  function onStateChange() {
    const state = getState();

    if (state.stage === 'idle') {
      return;
    }

    if (state.stage === 'render') {
      const renderCallback = state.renderQueue[state.renderQueueIndex];

      if (!renderCallback) {
        throw new Error('No more render callbacks in the queue');
      }

      if (renderCallback.state !== 'waiting') {
        return;
      }

      send({ type: 'RUN_RENDER_CALLBACK' });
      renderCallback.callback();
      send({ type: 'RENDER_DONE' });
      return;
    }

    if (state.stage === 'pre_effects' && state.timeout === null) {
      const timeout = setTimeout(() => {
        send({ type: 'RUNNING_EFFECTS', timeout });

        const { prevEffects, effectsQueue } = state;

        for (let i = prevEffects.length; i < effectsQueue.length; i += 1) {
          prevEffects.push([() => undefined, null]);
        }

        if (prevEffects.length > effectsQueue.length) {
          throw new Error('Mismatched effect queue lengths. This is likely due to a conditional hook call.');
        }

        const effectsToRun: [PrevEffect, Effect][] = [];

        for (let i = 0; i < state.effectsQueue.length; i += 1) {
          const prevEffect = state.prevEffects[i];
          const effect = state.effectsQueue[i];

          if (prevEffect === undefined || !effect) {
            throw new Error('Mismatched effect queue lengths. This is likely due to a bug in scheduler module.');
          }

          const prevDeps = prevEffect[1];
          const currentDeps = effect[1];

          if (!depsAreEqual(prevDeps, currentDeps)) {
            prevEffect[0]();
            prevEffect[1] = currentDeps;
            effectsToRun.push([prevEffect, effect]);
          }

          state.prevEffects[i] = prevEffect;
        }

        for (let i = 0; i < effectsToRun.length; i += 1) {
          const [prevEffect, effect] = effectsToRun[i];
          prevEffect[0] = effect[0]() || (() => undefined);
        }

        send({ type: 'EFFECTS_DONE' });
      }, 0);

      send({ type: 'SET_EFFECTS_TIMEOUT', timeout });
    }
  }

  return {
    render: (renderCallback: VoidFunction) => send({ type: 'RENDER', renderCallback }),
    effect: (effect: Effect) => send({ type: 'EFFECT', effect }),
    unmount: () => send({ type: 'UNMOUNT' }),
  };
};

export const scheduler = getScheduler();
