import type { SendWidgetEvent } from '../types';

const getSendWidgetEventState = (): {
  setIframe: (element: HTMLIFrameElement | null) => void;
  sendWidgetEvent: SendWidgetEvent;
} => {
  let iframe: HTMLIFrameElement | null = null;
  return {
    setIframe: (element) => {
      iframe = element;
    },
    sendWidgetEvent: (event) => iframe?.contentWindow?.postMessage(event, '*'),
  };
};

export const sendWidgetEventState = getSendWidgetEventState();
