import { getWidgetUrl } from './utils/getWidgetUrl';
import { sendWidgetEventState } from './utils/sendWidgetEventState';
import { forseRender, onlyForHooksAddOnUnmount } from './renderer';
import type { Options } from './types';

interface IframeOptions extends Pick<Options, 'brand' | 'siteCode' | 'className' | 'isVisible' | 'paymentToken'> {
  container: HTMLElement | null;
  onLoad?: (iframe: HTMLIFrameElement) => void;
  onSetRef?: (iframe: HTMLIFrameElement | null) => void;
}

const getPropsDiff = (prevOptions: IframeOptions | null, nextOptions: IframeOptions): Partial<IframeOptions> => {
  if (!prevOptions) {
    return nextOptions;
  }

  if (prevOptions === nextOptions) {
    return {};
  }

  const diff: Partial<IframeOptions> = {};

  for (const key of Object.keys(prevOptions) as (keyof IframeOptions)[]) {
    if (prevOptions[key] !== nextOptions[key]) {
      diff[key] = nextOptions[key] as never;
    }
  }

  return diff;
};

const getRenderIframe = () => {
  let element: HTMLIFrameElement | null = null;
  let prevOptions: IframeOptions | null = null;
  let prevUrl = '';

  onlyForHooksAddOnUnmount(() => {
    element?.remove();
    element = null;
    prevOptions = null;
  });

  const render = (options: IframeOptions): HTMLIFrameElement | undefined => {
    const diff = getPropsDiff(prevOptions, options);

    prevOptions = options;

    const { container } = options;

    if (!container) {
      element?.remove();
      element = null;
      options.onSetRef?.(null);
      return;
    }

    const widgetUrl = getWidgetUrl(options);

    if (!element || !container.contains(element)) {
      element = (container.querySelector(`iframe[src="${widgetUrl}"`) || document.createElement('iframe')) as HTMLIFrameElement;
    }

    const prevElement = element;

    if (element.parentElement !== container) {
      element?.remove();
      element = document.createElement('iframe') as HTMLIFrameElement;
      element.title = 'Payment Widget';
      element.height = '100%';
      element.width = '100%';
      element.allow = 'fullscreen; clipboard-read; clipboard-write';
    }

    if (widgetUrl !== prevUrl) {
      prevUrl = widgetUrl;
      element.src = widgetUrl;
    }

    if ('className' in diff) {
      element.className = diff.className || '';
    }

    if ('onLoad' in diff) {
      element.onload = () => {
        if (element) {
          options.onLoad?.(element);
          element.dataset.loaded = 'true';
          forseRender();
        }
      };
    }

    if ('isVisible' in diff) {
      if (diff.isVisible) {
        element.style.position = 'static';
        element.style.transform = 'none';
        element.style.opacity = '1';
        element.style.pointerEvents = 'auto';
      } else {
        element.style.position = 'absolute';
        element.style.transform = 'translate(-1000vw, -1000vh)';
        element.style.opacity = '0';
        element.style.pointerEvents = 'none';
      }
    }

    if (prevElement !== element) {
      sendWidgetEventState.setIframe(element);
      options.onSetRef?.(element);
    }

    if (!container.contains(element)) {
      container.appendChild(element);
    }

    return element;
  };

  return render;
};

export const iframe = getRenderIframe();
