import type { Options } from '../types';

import { useMemoSDK } from './useMemoSDK';

export const useRStatParamsSDK = (userCurrency: Options['userCurrency']) => {
  return useMemoSDK(() => {
    if (!userCurrency) {
      return {
        page_type: 'widget',
      };
    }

    return {
      currency: userCurrency,
      page_type: 'widget',
    };
  }, [userCurrency]);
};
