import { onlyForHooksAddOnRender, render, reset, sendWidgetEvent, unmount } from './renderer';
import type { PaymentWidgetSDK } from './types';
import { useAppSDK } from './useAppSDK';

export * from './types';

onlyForHooksAddOnRender(useAppSDK);

const paymentWidgetSDK: PaymentWidgetSDK = {
  version: __APP_VERSION__,
  unmount,
  render,
  reset,
  sendWidgetEvent,
};

window.paymentWidgetSDK = paymentWidgetSDK;

declare global {
  interface Window {
    paymentWidgetSDK: PaymentWidgetSDK;
  }
}

if (typeof window === 'undefined') {
  console.error('SDK is only available in the browser environment. Please run this script in a browser.');
}
