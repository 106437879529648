import { getContainer } from './utils/getContainer';
import { makeSubscription } from './utils/makeSubscription';
import { sameValue } from './utils/sameValue';
import { sendWidgetEventState } from './utils/sendWidgetEventState';
import { scheduler } from './scheduler';
import type { Options } from './types';

const sameProps = (a: Options | null, b: Options) => {
  if (!a) {
    return false;
  }

  if (a === b) {
    return true;
  }

  for (const [key, value] of Object.entries(a)) {
    if (!sameValue(value, b[key as keyof Options])) {
      return false;
    }
  }

  return true;
};

const getRenderer = () => {
  let mutationObserver: MutationObserver | null = null;
  const cleanupFunctions: VoidFunction[] = [];

  const unmount = () => {
    scheduler.unmount();

    for (const cleanup of cleanupFunctions) {
      cleanup();
    }
  };

  // It is possible to mount the iframe to a dom node that is not yet mounted to a parent
  // so for this case we try to attach the observer on every render.
  const attachObserver = (container: HTMLElement) => {
    if (!container?.parentElement) {
      return;
    }

    if (mutationObserver) {
      return;
    }

    mutationObserver = new MutationObserver(() => {
      if (!document.body.contains(container)) {
        unmount();
      }
    });

    mutationObserver.observe(container.parentElement, { childList: true });
  };

  cleanupFunctions.push(() => {
    if (mutationObserver) {
      mutationObserver.disconnect();
      mutationObserver = null;
    }
  });

  const propsSubscription = makeSubscription<Options>();

  const mount = (container: HTMLElement) => {
    attachObserver(container);
  };

  const forseRender = () =>
    scheduler.render(() => {
      const props = propsSubscription.getValue();
      const container = props?.container ? getContainer(props.container) : null;

      if (!container) {
        return;
      }

      propsSubscription.next(props);
    });

  const render = (options: Options) =>
    scheduler.render(() => {
      const prev = propsSubscription.getValue();

      if (sameProps(prev, options)) {
        return;
      }

      const newContainer = getContainer(options.container);

      if (!newContainer) {
        unmount();
        console.error('You should create a container for the iframe to render in before calling the render function');
        return;
      }

      const currentContainer = prev ? getContainer(prev.container) : null;

      if (newContainer !== currentContainer) {
        unmount();
        mount(newContainer);
      }

      propsSubscription.next(options);
    });

  // только для одноразового использования для создания хуков
  const onlyForHooksAddOnRender = (callback: (options: Options) => void) => {
    return propsSubscription.subscribe(callback);
  };

  // только для одноразового использования для создания хуков
  const onlyForHooksAddOnUnmount = (cleanup: VoidFunction) => {
    cleanupFunctions.push(cleanup);
  };

  const reset = () => {
    unmount();
    const container = getContainer(propsSubscription.getValue().container);

    if (!container) {
      return;
    }

    mount(container);
    forseRender();
  };

  return {
    render,
    unmount,
    onlyForHooksAddOnRender,
    onlyForHooksAddOnUnmount,
    forseRender,
    reset,
    sendWidgetEvent: sendWidgetEventState.sendWidgetEvent.bind(window),
  };
};

export const {
  /**
   * Перед вызовом render не забудь вызвать mount(container)
   */
  render,
  /**
   * Только для одноразового использования для создания хуков
   */
  onlyForHooksAddOnRender,
  /**
   * Только для одноразового использования для создания хуков
   */
  onlyForHooksAddOnUnmount,
  forseRender,
  unmount,
  reset,
  sendWidgetEvent,
} = getRenderer();
